import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Link, graphql } from 'gatsby'

import Layout from '../parts/layout' 


const ArticleTemplate = ({ data }) => {
  const date = new Date(data.strapiArticle.date)

  return (
    <Layout>
      <section>
        <div className="row">
          <div className="meta">{ date.toLocaleDateString("en-GB", { day: 'numeric', month: 'short', year: 'numeric' }) } / <em>{ data.strapiArticle.author }</em></div>
          <h1>{data.strapiArticle.title}</h1>
          <div className="content">{ ReactHtmlParser(data.strapiArticle.body) }</div>
          <Link to="/deps/" className="back">&laquo; all articles</Link>
        </div>
      </section>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: {eq: $id}) {
      id
      title
      date
      author
      body
    }
  }
`